<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <!-- TODO: Add create button for challenges -->
        </template>

        <template v-slot:datatable>
            <DataTable 
                :config="datatableConfig"
                :elements="challenges"
                :stripe="true"
                :draggable="false"
                :sst="true"
                @action="handleAction"
                @filter="filter"
                :pagination="pagination" 
                :customActions = "customActions"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>

import { v4 as uuidv4 } from 'uuid';
import sha256 from "crypto-js/sha256";
import loader from "@/modules/Shared/Mixins/loader";
import ChallengeService from "../Services/ChallengeService";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";
import ChallengeTranslateButton from "@/modules/Admin/Components/Challenges/ChallengeTranslateButton.vue";

export default {
    name: "ChallengesPage",
    components: {DataTable, DatatablePageLayout, ChallengeTranslateButton},
    mixins: [loader],
    data() {
        return {
            challenges: [],
            datatableConfig: [
                { 'field': 'title', head: this.$t('admin.challenges.title'), 'searchable': true, 'sortable': false },
                { 'field': 'state_uppercase', head: this.$t('admin.challenges.state'), 'type': 'html', 'searchable': false, 'sortable': false },
                { 'field': 'published', head: this.$t('admin.challenges.published'), 'type': 'check', 'searchable': false, 'sortable': false },
                { 'field': 'draft', head: this.$t('admin.challenges.draft'),  'type': 'check', 'searchable': false, 'sortable': false },
                { 'field': 'total_joined_users', head: this.$t('admin.challenges.total_joined_users'), 'searchable': false, 'sortable': false },
                { 'field': 'localized_languages', head: this.$t('admin.challenges.localized_languages'), 'searchable': false, 'sortable': false },
                { 'field': 'actions', 'type': 'actions', head: this.$t("geofences.geofences-messages.actions"), 'searchable': false, 'sortable': false }
            ],
            pagination: {
                per_page: 30,
                total: 0,
            },
            customActions: [
                { actionName: 'translation', component: ChallengeTranslateButton, props:{} }
            ]
        }
    },
    async created() {
        await this.getChallenges({
            filters: [],
            page: 1
        })
    },
    methods: {
        async getChallenges(params) {
            await this.loadAndNotify(async () => {
                let challengesResponse = await ChallengeService.getChallenges(PaginationService.parseParameters(params))
                this.challenges =  this.parseChallenges(challengesResponse.data)
                this.pagination = {
                    per_page: challengesResponse.per_page,
                    total: challengesResponse.total
                }
            }, true)
        },
        parseChallenges(challenges) {
            return challenges.map((challenge) => ({
                ...challenge,
                localized_languages: challenge.languages.map(l => l.acronym.toUpperCase()).join(', '),
                state_uppercase: this.parseState(challenge.state),
                actions: [ 'translation', 'clone', 'delete' ]
            }))
        },
        parseState(state) {
            switch (state) {
                case 'pending': return `<div class="capitalize border border-info text-info py-2 px-4 rounded-full">${state}</div>`
                case 'active': return `<div class="capitalize border border-success text-success py-2 px-4 rounded-full">${state}</div>`
                case 'finished': return `<div class="capitalize border border-danger text-danger py-2 px-4 rounded-full">${state}</div>`
                case 'closed': return `<div class="capitalize border text-grey py-2 px-4 rounded-full">${state}</div>`
                default: return  `<div class="capitalize border border-info text-info py-2 px-4 rounded-full">${state}</div>`
            }
        },
        handleAction(event, uuid) {
            switch(event) {
                case 'clone':
                    this.clone(uuid)
                    break
                case 'delete':
                    this.deleteChallenge(uuid)
                    break
            }
        },

        async deleteChallenge(uuid) {
            this.loadAndNotify(async () => {
                await ChallengeService.deleteChallenge(uuid)
                await this.getChallenges({ filters: [], page: 1 })
            })
        },

        clone(uuid) {
            this.$vs.dialog({
                type:'confirm',
                color:'primary',
                title: this.$t("admin.challenges.clone.title.confirmation"),
                text: this.$t("admin.challenges.clone.text.confirmation"),
                accept: () => this.cloneAction(uuid)
            })
        },

        cloneAction(uuid) {
            this.loadAndNotify(async () => {
                await ChallengeService.cloneChallenge(uuid, sha256(uuidv4()))
                try {
                    await this.getChallenges({ filters: [], page: 1 })
                } catch (e) {
                    this.$router.go()
                }
            })
        },

        async filter(params) {
            await this.getChallenges(params)
        },
    }
};
</script>